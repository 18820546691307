export const ForwardArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.031"
      height="16.031"
      viewBox="0 0 16.031 16.031"
    >
      <path
        id="Path_49"
        data-name="Path 49"
        d="M-3.984-12.984H-16.172l5.578-5.625L-12-20.016-20.016-12-12-3.984l1.406-1.406-5.578-5.625H-3.984Z"
        transform="translate(-3.984 -3.984) rotate(180)"
        fill="#fff"
      />
    </svg>
  );
};
