import React, { useState, useEffect } from 'react';
import { updateCustomerLead } from '../../../redux/actions';
import { toastNotifySuccess } from '../../Common/Toast';
import { ForwardArrow } from '@/assets/svgExports/ForwardArrow';
import { useSelector, useDispatch } from 'react-redux';
import { getLeadData, setLeadData } from '@/utils/localStorageFunctions';
import { LEAD_GENERATION_TYPES } from '@/utils/constants';
import { validateEmailError } from '@/utils/validateEmailError';
import useStoreContactDetails from 'src/hooks/useStoreContactDetails';

const SubscriberInput = () => {
  const { leadgenInfo, userData, store } = useSelector((state) => ({
    store: state.storeReducer.store,
    leadgenInfo: state.leadGenReducer.leadgenInfo,
    userData: state.userReducer.data,
  }));
  const [sColor, setColor] = useState('');
  const [leadInput, setLeadInput] = useState('');
  const [leadError, setLeadError] = useState(false);
  const [isSubmitInTransit, setIsSubmitInTransit] = useState(false);

  const { storePhone } = useStoreContactDetails();

  const dispatch = useDispatch();

  useEffect(() => {
    if (store?.theme?.colors?.primary_color) {
      setColor(store?.premium ? store?.theme?.colors?.primary_color : '#000000');
    }
  }, [store?.theme?.colors?.primary_color]);

  const handleLeadInput = (e) => {
    const value = e?.target?.value;
    if (
      (leadgenInfo?.type === LEAD_GENERATION_TYPES.PHONE && value.length <= 10) ||
      leadgenInfo?.type === LEAD_GENERATION_TYPES.EMAIL
    )
      setLeadInput(value);
  };

  const submitLeadInput = () => {
    setIsSubmitInTransit(true);
    const phoneReg = /(6|7|8|9)\d{9}/;
    const emailReg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const payload = {
      store_id: store?.store_id,
      lead_user_phone: '',
      lead_email_id: '',
      user_phone: userData?.phone,
    };
    if (leadgenInfo?.type === LEAD_GENERATION_TYPES.PHONE)
      payload['lead_user_phone'] = leadInput;
    else payload['lead_email_id'] = leadInput;
    if (payload['lead_email_id']) {
      const status = validateEmailError(payload['lead_email_id']);
      if (!status) {
        return;
      }
    }
    const callback = () => {
      toastNotifySuccess('Thanks for subscribing!');
      setLeadData(leadInput);
      setLeadInput('');
      setTimeout(() => setIsSubmitInTransit(false), 500);
    };

    if (
      leadgenInfo?.type === LEAD_GENERATION_TYPES.PHONE &&
      leadInput.length === 10 &&
      leadInput.match(phoneReg)
    ) {
      dispatch(updateCustomerLead(payload, callback));
    } else if (leadInput.match(emailReg)) dispatch(updateCustomerLead(payload, callback));
    else setLeadError(true);
  };

  const renderError = () => {
    return (
      <div className="error-msg flex  f7 red  flex justify-start items-center  mt2">
        <span
          className="ba b--red flex justify-center items-center br-pill bg-red white mr2 error-icon"
          style={{ fontSize: 8, padding: '2px 6px' }}
        >
          {' '}
          !{' '}
        </span>

        {`Please enter a valid ${
          leadgenInfo?.type === LEAD_GENERATION_TYPES.EMAIL ? 'email ID' : 'phone number'
        }`}
      </div>
    );
  };

  return !!storePhone && leadgenInfo?.status && !getLeadData() ? (
    <div className="mt16px">
      <div className="f6 mb2">Subscribe for latest updates & offers</div>
      <div className="flex justify-start items-center">
        <input
          placeholder={`Enter ${
            leadgenInfo?.type === LEAD_GENERATION_TYPES.EMAIL ? 'Email' : 'Phone Number'
          }`}
          value={leadInput}
          className="f6 bg-white pv2 ph2 b--black-10 w-100 rm-input-num-arrows"
          onChange={handleLeadInput}
          type={`${
            leadgenInfo?.type === LEAD_GENERATION_TYPES.EMAIL ? 'email' : 'number'
          }`}
        ></input>
        <button
          className="bn pointer"
          aria-label="Subscribe for latest offers"
          style={{
            backgroundColor: `${
              sColor || store?.store_info?.theme?.colors?.primary_color
            }`,
            borderRadius: '0px 4px 4px 0px',
            padding: '9px 12px 8px 12px',
          }}
          onClick={submitLeadInput}
          disabled={isSubmitInTransit}
        >
          {' '}
          <ForwardArrow color="#0000000" />
        </button>
      </div>
      {leadError ? renderError() : null}
    </div>
  ) : null;
};

export default SubscriberInput;
