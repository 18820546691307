export const getFooterDSLabel = (componentsList, footerNum = '') => {
  const footerConfig = componentsList?.Footer?.[0]?.config;
  const shouldHideDSLabel = footerConfig?.hide_ds_label;

  if (!shouldHideDSLabel) {
    return (
      <>
        Powered by &nbsp;
        <a
          href="https://download.digitalshowroom.app/download-cfe"
          target="_blank"
          className="underline copyright-text-grey-color"
          rel="website app link noreferrer"
        >
          DIGITAL SHOWROOM APP
        </a>
      </>
    );
  }
  return null;
};
