import React, { useEffect, useState } from 'react';
import NavigationItems from '@/components/DesignSystem/NavigationItems';
import { useDispatch, useSelector } from 'react-redux';
import router from 'next/router';
import { getRoute, PATH } from '@/utils/routes';
import SubscriberInput from '../../SubscriberInput';
import LogoutModal from '@/components/LogoutModal';
import { deviceWidth } from '@/utils/deviceWidth';
import { commonToggleAuthModal } from '@/utils/commonToggleAuthModal';
import { executeLogout } from '@/utils/excuteLogout';
import getCustomCtaText from '@/utils/getCustomCtaText';
import { FooterMap2 } from '@/components/FooterMap';
import {
  CANCELATION_AND_REFUND_POLICY_URL,
  DS_TERMS_AND_CONDITIONS_URL,
  MAX_MOBILE_WIDTH,
} from '@/utils/constants';
import { getNavigationPagesList, getStorePolicies } from '@/redux/actions';
import { FooterStorePolicies } from '../FooterStorePolicies';
import { useFeatureLocks } from '@/hooks/useFeatureLocks';
import { getFooterDSLabel } from '../footerUtils';
import { useSSRSelector } from '@/redux/ssrStore';
import useStoreLogoOrNameVisibility from '@/hooks/useStoreLogoOrNameVisibility';
import getActualOrDsStoreLogo from '@/utils/getActualOrDsStoreLogo';
import useStoreContactDetails from 'src/hooks/useStoreContactDetails';

const Footer = (props) => {
  const { user } = useSelector((state) => ({
    user: state.userReducer,
  }));

  const { storeData, navigationList, storePolicies, catalogCategories, productTags } =
    useSSRSelector((state) => ({
      storeData: state.storeReducer.store,
      storePolicies: state.storePolicyReducer.data,
      navigationList: state.additionalPagesReducer.navigationList,
      catalogCategories: state.catalogReducer.categories,
      productTags: state.catalogReducer.productTags,
    }));

  const { isStoreLogoInFooter, isStoreNameInFooter } = useStoreLogoOrNameVisibility();
  const storeLogo = getActualOrDsStoreLogo(storeData);
  const { storeAddress, storePhone, storeEmail } = useStoreContactDetails();

  const { leadGenFeatureStatus, visitorLoginFeatureStatus } = useFeatureLocks();
  const isLeadGenVisible = leadGenFeatureStatus?.isVisible;
  const isVisitorLoginVisible = visitorLoginFeatureStatus?.isVisible;

  const dispatch = useDispatch();
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
  const headerName = storeData?.theme?.components?.Header?.[0]?.name;
  const isLoginVisible = props?.config?.hidden_components?.login_view;
  const dsLabel = getFooterDSLabel(storeData?.theme?.components);

  useEffect(() => {
    if (storeData?.store_id && !storePolicies?.length) {
      dispatch(getStorePolicies({ store_id: storeData.store_id }));
    }
  }, [storeData?.store_id, storePolicies?.length]);

  useEffect(() => {
    if (storeData?.store_id) {
      dispatch(
        getNavigationPagesList({
          storeId: storeData?.store_id,
          isPublished: true,
        })
      );
    }
  }, [storeData?.store_id]);

  useEffect(() => {
    setShowLogoutConfirmation(false);
  }, [user?.isLogin]);

  function toggleAuthModal() {
    if (
      commonToggleAuthModal({
        userLogin: user?.isLogin,
        storeInfoDomain: storeData?.store_info?.domain,
      })
    )
      toggleLogoutModal();
  }

  function toggleLogoutModal() {
    setShowLogoutConfirmation(!showLogoutConfirmation);
  }

  function renderLoginLogout() {
    return (
      <>{deviceWidth > MAX_MOBILE_WIDTH ? showLoginForDesktop() : showLoginForMobile()}</>
    );
  }

  function showLoginForMobile() {
    if (headerName === 'Header14' && !isLoginVisible) {
      return null;
    } else {
      return (
        <div
          style={{ marginLeft: 33, marginTop: -16 }}
          onClick={toggleAuthModal}
          className="cfeNavItems__item fw6"
        >
          {user?.isLogin ? 'Logout' : 'Login'}
        </div>
      );
    }
  }

  function showLoginForDesktop() {
    if (headerName === 'Header14' && !isLoginVisible) {
      return null;
    }
    return (
      <span
        style={{ marginLeft: 0 }}
        onClick={toggleAuthModal}
        className="cfeNavItems__item fw6"
      >
        {user?.isLogin ? 'Logout' : 'Login'}
      </span>
    );
  }

  function authAndLogoutModal() {
    return (
      <>
        {showLogoutConfirmation && (
          <LogoutModal
            toggleLogoutModal={toggleLogoutModal}
            executeLogout={executeLogout}
            showLogoutConfirmation={showLogoutConfirmation}
          />
        )}
      </>
    );
  }

  const renderSocialMediaProfileDetails = () => {
    if (
      !storeData.social_media_profile_details?.media_count ||
      storeData.social_media_profile_details?.media_count < 1
    )
      return null;
    return (
      <div
        className="footer-social-media-container"
        style={{ width: '246px', marginBottom: '1rem' }}
      >
        <h4>Follow us here</h4>
        <div className="social-media-section flex space-between">
          {storeData.social_media_profile_details?.media_detail.map((profile) => {
            return profile.profile_url !== '' ? (
              <div key={profile.id} className="social-media-icon">
                <a href={profile.profile_url} target="_blank" rel="noreferrer">
                  <img src={profile.image_url} alt={`${profile?.name} image`} />
                </a>
              </div>
            ) : null;
          })}
        </div>
      </div>
    );
  };

  function getCurrentYear() {
    return new Date().getFullYear() || '';
  }

  const renderEmailAndPhoneNumber = () => {
    return (
      <div>
        {!!storeEmail && (
          <div className="store-information__subsection" style={{ marginTop: 40 }}>
            <span>
              <img alt="mail" src="https://cdn.dotpe.in/kiranaStatic/icons/mail.png" />
            </span>
            <a href={`mailto:${storeEmail}`}>{storeEmail}</a>
          </div>
        )}
        {!!storePhone && (
          <div
            className="store-information__subsection"
            style={{
              marginTop: storeEmail ? 10 : 40,
            }}
          >
            <span className="flex">
              <img
                alt="phone"
                src="https://cdn.dotpe.in/kiranaStatic/icons/ringingtelephone.png"
              />
            </span>
            <a href={`tel:${storePhone}`}>{storePhone}</a>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      {authAndLogoutModal()}
      <footer className="Footer2">
        <div className="Footer2__top-section">
          <div
            className="flex justify-between center Footer2__top-section--inner"
            style={{
              maxWidth: storeData?.address?.visibility === 2 ? '1000px' : '100%',
              padding: storeData?.address?.visibility === 2 ? '0 2rem' : '0',
            }}
          >
            <div className="Footer2__section footer-shop-desc">
              <div className="top-section">
                {storeLogo && isStoreLogoInFooter && (
                  <img src={storeLogo} height="67" width="67" alt="store logo" />
                )}
                {isStoreNameInFooter && <h2>{storeData?.store_info?.name}</h2>}
              </div>
              {isLeadGenVisible ? (
                <div className="bottom-section">
                  <SubscriberInput />
                </div>
              ) : null}
              {(storeData?.store_info?.description ||
                storeData?.store_info?.description?.length > 0) && (
                <div className="bottom-section">
                  <h4>About Us</h4>
                  {storeData?.store_info?.description?.length > 160 ? (
                    <p className="desc-text">
                      {storeData?.store_info?.description?.substr(0, 160)}...
                    </p>
                  ) : (
                    <p className="desc-text">{storeData?.store_info?.description}</p>
                  )}
                </div>
              )}
              {storeData?.store_info?.description?.length > 160 && (
                <p
                  className="desc-text__view-more fw6 pointer underline-hover"
                  onClick={() => {
                    router.push(getRoute(PATH.ABOUT_US, storeData?.store_info?.domain));
                  }}
                >
                  View More
                </p>
              )}
            </div>
            <div className="Footer2__section flex-column">
              <NavigationItems
                categories={[...productTags, ...catalogCategories] || []}
                horizontalNav={false}
                navigationItems={navigationList || []}
                isFooter={true}
                headerName={headerName}
              />
              {isVisitorLoginVisible && renderLoginLogout()}
              {renderEmailAndPhoneNumber()}
            </div>
            {storeAddress && (
              <div className="Footer2__section store-information">
                <div>
                  <h4>Visit Us</h4>
                  <p className="address-text">{storeAddress}</p>
                </div>
              </div>
            )}
            {storeData?.social_media_profile_details && renderSocialMediaProfileDetails()}
            <FooterMap2 storeData={storeData} />
          </div>
        </div>
        <FooterStorePolicies storePolicies={storePolicies} path={PATH.STORE_POLICIES} />
        <div className="Footer2__bottom-section">
          {!props?.config?.hidden_components?.payment_method_images_not_visible && (
            <div style={{ display: 'none' }} className="flex items-center mr2">
              <span className="payment-text mr3-ns mb0-ns mb3">
                {getCustomCtaText('pay_after_cta')}
              </span>
              <div className="flex items-center">
                <img src="/assets/images/ic_paytm@2x.png" className="mr3" height="14" />
                <img src="/assets/images/gpay.png" className="mr3" height="17" />
                <img
                  src="/assets/images/phonepe-with-text@2x.png"
                  className="mr3"
                  height="26"
                />
                <img src="/assets/images/pay-in-cash@2x.png" height="22" />
              </div>
            </div>
          )}
          <div className="flex items-center footer__bottom-text">
            <p>
              Copyright {getCurrentYear()}
              {dsLabel ? <>. {dsLabel}</> : ''}
              {' | '}
              <a
                href={CANCELATION_AND_REFUND_POLICY_URL}
                target="_blank"
                rel="noreferrer"
              >
                Refunds &amp; Cancellation
              </a>
              {' | '}
              {storeData?.services?.delivery_time_approx ? (
                <a href={DS_TERMS_AND_CONDITIONS_URL} target="_blank" rel="noreferrer">
                  Terms &amp; Conditions
                </a>
              ) : (
                <a href={DS_TERMS_AND_CONDITIONS_URL} target="_blank" rel="noreferrer">
                  Terms &amp; Conditions
                </a>
              )}
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
