import { getRoute, PATH } from './routes';
import router from 'next/router';

/**
 * common function to initiate auth toggle
 * @param {*} param0
 * @return {boolean} return
 */
export const commonToggleAuthModal = ({ userLogin, storeInfoDomain }) => {
  if (userLogin) {
    return true;
  } else {
    router.push(getRoute(PATH.ORDERS, storeInfoDomain));
  }
  return false;
};
