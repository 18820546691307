import React, { useEffect, useRef, useState } from 'react';
import { ADDRESS_VISIBILITY } from '@/utils/constants';
import areEqual from './util/arePropsEqual';
import { getGoogleMapUrl } from '@/utils/getGoogleMapUrl';

function FooterMap3(props) {
  const { storeData = {} } = props;
  const { address = {} } = storeData;
  const [loadMap, setLoadMap] = useState(false);

  const containerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setLoadMap(true);
      }
    });
    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef?.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  const addressVisibilityHidden = (visibilityValue) =>
    address?.visibility === visibilityValue;

  const onHover = () => {
    document.getElementById('mapFrame').style.filter = 'brightness(100%)';
    document.getElementById('mapText').style.display = 'none';
  };

  const onHoverLeave = () => {
    document.getElementById('mapFrame').style.filter = 'brightness(40%)';
    document.getElementById('mapText').style.display = 'block';
  };

  return (
    addressVisibilityHidden(ADDRESS_VISIBILITY.VISIBLE) &&
    !!address &&
    !!address?.latitude &&
    !!address?.longitude && (
      <section
        ref={containerRef}
        className="Footer3__map-section"
        onMouseEnter={onHover}
        onMouseLeave={onHoverLeave}
      >
        <iframe
          loading="lazy"
          className="map-frame"
          id="mapFrame"
          width="100%"
          height="300px"
          title="Address"
          src={loadMap ? getGoogleMapUrl(address?.latitude, address?.longitude) : ''}
        />
        <p className="map-text" id="mapText">
          Find us here
        </p>
      </section>
    )
  );
}

export default React.memo(FooterMap3, areEqual);
